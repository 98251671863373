@use '../util' as *;

html {
	font-size: 100%;
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	font-family: var(--font-body) !important;

	@include breakpoint(large) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: absolute;
		width: calc(100% - 3rem) !important;
		left: 50% !important;
		transform: translateX(-50%) !important;
	}
}
