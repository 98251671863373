@use '../util' as *;

// background color
section {
    background-color: var(--white);
}

// a styles 
a {
    text-decoration: none !important;
    color: var(--white) !important;
}

//footer styles 
footer {
    background-color: var(--black);
    color: var(--white);
    text-align: center;
    padding: 80px;
    width: 100%;
    
}

.tags {
    color: #737377;
    font-style: italic;
    font-size: rem(19);
    font-family: var(--font-section);
}

// Top of site content 
.hero {
    background-color: var(--black);
    height: 100vh;
    width: 100%;
    color: var(--white);
    font-size: rem(40);

    // Tag Styles 
    .body-open {
        margin-left: rem(30) !important;
    }

    .h1-close {
        display: flex;
        width: auto;
        justify-content: end;
    }

    .p-close {
        display: flex;
        width: 55%;
        justify-content: end;
    }


    // Hero content area
    &__area {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }
    // Hero Logo styling
    &__socials {
        padding-bottom: rem(200);
        margin-right: rem(30);
    }

    // Hero text styling / button
    &__text {
        margin-left: rem(80);
        margin-bottom: rem(200);
    }

    &__button {
        width: rem(175);
        height: rem(55);
        border-radius: rem(30);
        background-color: var(--orange);
        text-align: center;
        font-size: rem(20);
        padding-top: rem(10);
        margin-top: rem(20);
    }

    &__button:hover {
        background-color: var(--orange-state);
        cursor: pointer;
    }
    
    &__button:active {
        background-color: #A9A9A9;
        color: #808080;
    }

    &--header {
        font-size: rem(85);
        font-family: var(--font-header);
        padding-bottom: rem(10);
        margin: 0;
    }

    &--text {
        font-size: rem(30);
        margin: 0;
    }
}

/////////////////////////////////////////

// Tag Styles 
.nav-open{
    position: absolute;
    margin: rem(0) rem(0) rem(110) rem(42);
}

.nav-close{
    position: absolute;
    display: flex;
    justify-content: end;
    width: 92%;
    margin-top: rem(90);

    @include breakpoint(xlarge) {
        width: 97% !important;
    }
}

// Nav Stylings 
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: rem(20);

    &__logo {
        height: rem(80);
        margin: rem(30);
    }
}

.nav-links {
    display: flex;
    list-style-type: none;
    width: rem(500);
    justify-content: space-around;
    font-size: rem(28);
    
    &__li {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-right: rem(10);
    }

    &__button {
        width: rem(160);
        height: rem(55);
        border-radius: rem(30);
        border: none;
        background-color: var(--orange);
        text-align: center;
        font-size: rem(25);
        
    }

    &__button:hover {
        background-color: var(--orange-state);
        cursor: pointer;
    }
    
    &__button:active {
        background-color: #A9A9A9;
        color: #808080;
    }

}

////////////////////////////////////////

// Tag styles 
.s2-close {
    display: flex;
    align-items: flex-end;
}


// About me and Project Section
.sub {
    display: flex;
    justify-content: space-around;
    padding: rem(80);


    &__information {
        color: var(--black);
    }

    &--header {
        font-size: rem(55);
        margin-bottom: rem(20);
    }

    &--text {
        font-size: rem(28);
    }

    // Image Container x Styling
    &__container {
        display: flex;
        align-items: center;
    }

    &__headshot {
        width: rem(250);
        border-radius: rem(150);
        margin-left: rem(80);

        @include breakpoint(375) {
            margin-left: rem(0) !important;
            margin-right: rem(6) !important;
        }
    }
}

// Project card Styling. 
.sub-projects {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: rem(80);

    &--header {
        font-size: rem(50);
        margin-bottom: rem(30);
    }
    

    .s-open {
        display: flex;
    }

    .s-close {
        display: flex;
        justify-content: end;
        margin-top: rem(30);
    }
}

.project-container {
    display: flex;
    justify-content: space-around;
}

.project-card {
    width: rem(250);
    height: rem(500);
    box-shadow: 5px 5px 20px var(--project-shadow);
    margin: rem(10);

    &--header {
        font-size: rem(22);
        margin: rem(10)
    }

    &--text {
        font-size: rem(15);
    }

}

.project-image {
    margin-top: rem(25);
    width: rem(222);
    height: rem(211);
    border-radius: rem(125);
}

.project-link {
    text-decoration: none;
    color: var(--orange) !important;
}

small {
    display: flex;
    justify-content: center;
    font-size: rem(12);
    margin-top: rem(5);
}

hr {
    margin-left: rem(20);
    margin-right: rem(20);
}

///////////////////////////////////////////////

.footer {

    // Tags 
    .ft-open {
        display: flex;
        margin-left: rem(180);

        @include breakpoint(xlarge) {
            margin-left: rem(355);
        }
    }
    
    .ft-close {
        display: flex;
        justify-content: end;
        margin-right: rem(170);

        @include breakpoint(xlarge) {
            margin-right: rem(355);
        }
    }

    .body-close {
        display: flex;
        justify-content: end;
    }

    &--header {
        font-size: rem(55);
        text-transform: uppercase;
        margin-bottom: rem(15);
    }

    &--text {
        font-size: rem(25);
    }
}
///////////////////////////////////////////////