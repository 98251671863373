:root {
  --font-body: "Barlow", sans-serif;
  --font-header: "Nothing You Could Do", cursive;
  --font-section: "La Belle Aurore", cursive;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --orange: hsl(16, 100%, 66%);
  --orange-state: hsl(15, 84%, 62%);
  --project-shadow: hsl(0, 2%, 77%);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-body) !important;
}
@media (min-width: 71.875em) {
  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: calc(100% - 3rem) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

body {
  padding: 0 1.5rem;
  max-width: 96.875rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  body {
    padding: 0;
    transform: translate(0, 0);
  }
}

section {
  background-color: var(--white);
}

a {
  text-decoration: none !important;
  color: var(--white) !important;
}

footer {
  background-color: var(--black);
  color: var(--white);
  text-align: center;
  padding: 80px;
  width: 100%;
}

.tags {
  color: #737377;
  font-style: italic;
  font-size: 1.1875rem;
  font-family: var(--font-section);
}

.hero {
  background-color: var(--black);
  height: 100vh;
  width: 100%;
  color: var(--white);
  font-size: 2.5rem;
}
.hero .body-open {
  margin-left: 1.875rem !important;
}
.hero .h1-close {
  display: flex;
  width: auto;
  justify-content: end;
}
.hero .p-close {
  display: flex;
  width: 55%;
  justify-content: end;
}
.hero__area {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.hero__socials {
  padding-bottom: 12.5rem;
  margin-right: 1.875rem;
}
.hero__text {
  margin-left: 5rem;
  margin-bottom: 12.5rem;
}
.hero__button {
  width: 10.9375rem;
  height: 3.4375rem;
  border-radius: 1.875rem;
  background-color: var(--orange);
  text-align: center;
  font-size: 1.25rem;
  padding-top: 0.625rem;
  margin-top: 1.25rem;
}
.hero__button:hover {
  background-color: var(--orange-state);
  cursor: pointer;
}
.hero__button:active {
  background-color: #A9A9A9;
  color: #808080;
}
.hero--header {
  font-size: 5.3125rem;
  font-family: var(--font-header);
  padding-bottom: 0.625rem;
  margin: 0;
}
.hero--text {
  font-size: 1.875rem;
  margin: 0;
}

.nav-open {
  position: absolute;
  margin: 0rem 0rem 6.875rem 2.625rem;
}

.nav-close {
  position: absolute;
  display: flex;
  justify-content: end;
  width: 92%;
  margin-top: 5.625rem;
}
@media (min-width: 87.5em) {
  .nav-close {
    width: 97% !important;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;
}
.nav__logo {
  height: 5rem;
  margin: 1.875rem;
}

.nav-links {
  display: flex;
  list-style-type: none;
  width: 31.25rem;
  justify-content: space-around;
  font-size: 1.75rem;
}
.nav-links__li {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 0.625rem;
}
.nav-links__button {
  width: 10rem;
  height: 3.4375rem;
  border-radius: 1.875rem;
  border: none;
  background-color: var(--orange);
  text-align: center;
  font-size: 1.5625rem;
}
.nav-links__button:hover {
  background-color: var(--orange-state);
  cursor: pointer;
}
.nav-links__button:active {
  background-color: #A9A9A9;
  color: #808080;
}

.s2-close {
  display: flex;
  align-items: flex-end;
}

.sub {
  display: flex;
  justify-content: space-around;
  padding: 5rem;
}
.sub__information {
  color: var(--black);
}
.sub--header {
  font-size: 3.4375rem;
  margin-bottom: 1.25rem;
}
.sub--text {
  font-size: 1.75rem;
}
.sub__container {
  display: flex;
  align-items: center;
}
.sub__headshot {
  width: 15.625rem;
  border-radius: 9.375rem;
  margin-left: 5rem;
}
@media (min-width: ) {
  .sub__headshot {
    margin-left: 0rem !important;
    margin-right: 0.375rem !important;
  }
}

.sub-projects {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5rem;
}
.sub-projects--header {
  font-size: 3.125rem;
  margin-bottom: 1.875rem;
}
.sub-projects .s-open {
  display: flex;
}
.sub-projects .s-close {
  display: flex;
  justify-content: end;
  margin-top: 1.875rem;
}

.project-container {
  display: flex;
  justify-content: space-around;
}

.project-card {
  width: 15.625rem;
  height: 31.25rem;
  box-shadow: 5px 5px 20px var(--project-shadow);
  margin: 0.625rem;
}
.project-card--header {
  font-size: 1.375rem;
  margin: 0.625rem;
}
.project-card--text {
  font-size: 0.9375rem;
}

.project-image {
  margin-top: 1.5625rem;
  width: 13.875rem;
  height: 13.1875rem;
  border-radius: 7.8125rem;
}

.project-link {
  text-decoration: none;
  color: var(--orange) !important;
}

small {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  margin-top: 0.3125rem;
}

hr {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.footer .ft-open {
  display: flex;
  margin-left: 11.25rem;
}
@media (min-width: 87.5em) {
  .footer .ft-open {
    margin-left: 22.1875rem;
  }
}
.footer .ft-close {
  display: flex;
  justify-content: end;
  margin-right: 10.625rem;
}
@media (min-width: 87.5em) {
  .footer .ft-close {
    margin-right: 22.1875rem;
  }
}
.footer .body-close {
  display: flex;
  justify-content: end;
}
.footer--header {
  font-size: 3.4375rem;
  text-transform: uppercase;
  margin-bottom: 0.9375rem;
}
.footer--text {
  font-size: 1.5625rem;
}

@media (max-width: 47em) {
  .nav__wrapper {
    display: none;
  }
}

@media (max-width: ) {
  .nav-open {
    display: none;
  }
}

@media (max-width: ) {
  .nav-close {
    display: none;
  }
}

.nav__tog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 2.5rem;
  height: 0.9375rem;
  justify-content: space-around;
  cursor: pointer;
  transition: 0.5s;
  transform: center;
  margin-right: 1.875rem;
}
@media (min-width: 47em) {
  .nav__tog {
    display: none;
  }
}

.nav__tog span {
  height: 2px;
  background-color: white;
  transform-origin: center;
  transition: 0.2s;
}

.nav__tog span:nth-child(1) {
  width: 100%;
}

.nav__tog span:nth-child(2) {
  width: 80%;
}

.nav__tog.active span {
  background-color: white;
  position: absolute;
  left: 0%;
  width: 100%;
}

.nav__tog.active span:nth-child(1) {
  top: 40%;
  transform: rotate(45deg);
}

.nav__tog.active span:nth-child(2) {
  bottom: 40%;
  transform: rotate(-45deg);
}

@media (max-width: 47em) {
  .hero {
    background-color: var(--black);
    height: 100vh;
    width: 100%;
    color: var(--white);
    font-size: 2.5rem;
  }
  .hero .body-open {
    margin-left: 1.875rem !important;
  }
  .hero .h1-close {
    display: flex;
    width: auto;
    justify-content: center !important;
  }
  .hero .p-close {
    display: flex;
    width: 55%;
    justify-content: end;
  }
  .hero__area {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .hero__socials {
    padding-bottom: 15.625rem;
    margin-right: 1.875rem;
  }
  .hero__text {
    margin-left: 2.5rem !important;
    margin-bottom: 29rem;
  }
  .hero__button {
    width: 10rem;
    height: 3.4375rem;
    border-radius: 1.875rem;
    background-color: var(--orange);
    text-align: center;
    font-size: 1.25rem;
    padding-top: 0.625rem;
    margin-top: 1.25rem;
  }
  .hero__button:hover {
    background-color: var(--orange-state);
    cursor: pointer;
  }
  .hero__button:active {
    background-color: #A9A9A9;
    color: #808080;
  }
  .hero--header {
    font-size: 3.75rem !important;
    font-family: var(--font-header);
    padding-bottom: 0.625rem;
    margin: 0;
  }
  .hero--text {
    font-size: 1.375rem !important;
    margin: 0;
  }

  .sub {
    display: grid;
    grid-template-columns: 13.75rem 1fr !important;
  }
  .sub--header {
    font-size: 1.875rem;
    margin-bottom: 1.25rem;
  }
  .sub--text {
    font-size: 1.125rem !important;
  }
  .sub__headshot {
    width: 10.625rem !important;
    margin-left: 0.625rem !important;
  }

  .sub-projects--header {
    font-size: 2.5rem;
    margin-bottom: 1.875rem;
  }

  .footer .ft-open {
    display: flex;
    margin: 0.625rem !important;
  }
  .footer .ft-close {
    display: flex;
    justify-content: end;
    margin: 0.625rem !important;
  }
  .footer .body-close {
    display: flex;
    justify-content: end;
  }
  .footer--header {
    font-size: 1.875rem;
    text-transform: uppercase;
    margin-bottom: 0.9375rem;
  }
  .footer--text {
    font-size: 1.1875rem;
  }
}
@media (max-width: 60.875em) {
  body {
    padding: 0;
  }

  .hero {
    background-color: var(--black);
    height: 100vh;
    width: 100%;
    color: var(--white);
    font-size: 2.5rem;
  }
  .hero .body-open {
    margin-left: 1.875rem !important;
  }
  .hero .h1-close {
    display: flex;
    width: auto;
    justify-content: end;
  }
  .hero .p-close {
    display: flex;
    width: 55%;
    justify-content: end;
  }
  .hero__text {
    margin-left: 5rem;
    margin-bottom: 18.75rem;
  }
  .hero--header {
    font-size: 4.0625rem;
    font-family: var(--font-header);
    padding-bottom: 0.625rem;
    margin: 0;
  }
  .hero--text {
    font-size: 1.5625rem;
    margin: 0;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.25rem;
  }
  .nav__logo {
    height: 5rem;
    margin: 1.875rem 1.5625rem 1.875rem 1.875rem;
  }

  .nav-links {
    display: flex;
    list-style-type: none;
    width: 31.25rem;
    justify-content: space-around;
    font-size: 1.25rem;
    margin-bottom: 0rem;
  }
  .nav-links__li {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-right: 0.625rem;
  }
  .nav-links__button {
    width: 8.75rem;
    height: 3.4375rem;
    border-radius: 1.875rem;
    border: none;
    background-color: var(--orange);
    text-align: center;
    font-size: 1.25rem;
  }
  .nav-links__button:hover {
    background-color: var(--orange-state);
    cursor: pointer;
  }
  .nav-links__button:active {
    background-color: #A9A9A9;
    color: #808080;
  }

  .s2-close {
    display: flex;
    justify-content: flex-end;
    grid-column: 2/-1;
  }

  .sub {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .sub--header {
    font-size: 3.125rem;
    margin-bottom: 1.25rem;
  }
  .sub--text {
    font-size: 1.375rem;
  }
  .sub__headshot {
    width: 13.125rem;
    margin-left: 3.125rem;
  }

  .project-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .footer .ft-open {
    display: flex;
    margin-left: 6.25rem;
  }
  .footer .ft-close {
    display: flex;
    justify-content: end;
    margin-right: 5.625rem;
  }
  .footer .body-close {
    display: flex;
    justify-content: end;
  }
}