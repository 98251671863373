// 640px, 1150px, 1400px
$breakpoints-up: (
	'375': '23.4375em',
	'small': '20em',
	'medium': '47em',
	'large': '71.875em',
	'xlarge': '87.5em',
);

// 639px, 1149px, 1399px
$breakpoints-down: (
	'375': '23.4375em',
	'xxxsmall': '26.6em',
	'xxsmall': '47em',
	'786': '48em',
	'small': '60.875em',
	'medium': '71.8125em',
	'large': '87.4375em',
);

@mixin breakpoint($size) {
	@media (min-width: map-get($breakpoints-up, $size)) {
		@content;
	}
}

@mixin breakpoint-down($size) {
	@media (max-width: map-get($breakpoints-down, $size)) {
		@content;
	}
}
